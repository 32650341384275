/**
 * Component classes registered by plugins.
 *
 */

@import 'print.css';

@import 'tables.css';

@import 'prism.css';

@tailwind components;

@layer components {
    .link {
        @apply font-semibold leading-6 text-indigo-600 hover:text-indigo-500 hover:underline cursor-pointer;
    }

    .textarea {
        @apply block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500;
    }

    .title-2 {
        @apply text-[1.4rem] font-black;
    }

    .title-3 {
        @apply text-[20px] truncate font-black
    }

    .title-4 {
        @apply text-16 sm:text-18 font-bold
    }

    .break-words {
        word-break: break-word;
    }
}
